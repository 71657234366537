<template>
  <iframe class="app-iframe"
          :id="iframeId"
          :src="formatedAppUrl"
          :data-qa="`${appId}-app-iframe`"
          :title="`${appId}-app-iframe`"></iframe>
</template>
<script>
import communicationSdk, { startCommunicationWithSdk } from '@/modules/common/util/communicationSdk';

export default {
  name: 'EmbeddedAppDelegatorIframe',
  props: {
    appId: {
      type: String,
      required: true,
    },
    appName: {
      type: String,
    },
    appUrl: {
      type: String,
      required: true,
    },
    componentData: {
      type: Object,
    },
  },
  data() {
    return {
      appChannel: null,
    };
  },
  created() {
    this.settingChannel();
  },
  computed: {
    appDomain() {
      return new URL(this.appUrl).origin;
    },
    iframeId() {
      return `${this.appId}-app-iframe`;
    },
    formatedAppUrl() {
      if (this.componentData?.idInQuery) {
        return `${this.appUrl}?widgetUid=${this.appId}`;
      }
      return this.appUrl;
    },
  },
  methods: {
    settingChannel() {
      this.appChannel = startCommunicationWithSdk(this.appDomain, this.iframeId, this.appId);
      this.appChannel.onmessage = this.handleMessage;
    },
    async handleMessage({ data }) {
      const actionName = data.type;
      const payload = data.data;
      const enrichData = { ...payload, app_code_name: this.appName };
      if (actionName === 'SetState' && payload === 'ready') {
        this.ready();
        this.appChannel.postMessage({ type: actionName, isAck: true });
        return;
      }
      try {
        this.$emit('track', actionName, enrichData);
        const actionResponse = await communicationSdk(actionName, enrichData);
        this.appChannel.postMessage({ type: actionName, isAck: true, data: actionResponse });
      } catch (e) {
        console.error(e);
      }
    },
    ready() {
      this.$emit('ready');
    },
  },
};
</script>
<style scoped lang="scss">
.app-iframe {
  pointer-events: all;
  width: 100%;
  height: 100%;
  border: unset;
  display: block;
}
</style>
