import { staffService } from '@/modules/staff/services/staffService';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';

const state = {
  permissions: [],
  staffData: {},
  staffDataLoaded: false,
  staffList: [],
  allStaffLoaded: false,
  staffTeamsViewState: 'all',
};

const mutations = {
  setStaffData(state, staffData) {
    state.staffData = { ...state.staffData, [staffData.id]: { ...state.staffData[staffData.id], ...staffData } };
    state.staffDataLoaded = true;
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
  setAllStaff(state, staff) {
    state.staffList = staff;
    const staffObj = staff.reduce((a, v) => ({ ...a, [v.uid]: v }), {});
    state.staffData = { ...staffObj, ...state.staffData };
    state.allStaffLoaded = true;
  },
  setStaffTeamsView(state, teamViewState) {
    state.staffTeamsViewState = teamViewState;
  },
};

const actions = {
  async fetchStaffData({ rootGetters, commit, state }) {
    if (state.staffDataLoaded) return;
    try {
      const staffUid = rootGetters['AuthStore/staffUid'];
      const businessUid = rootGetters['AuthStore/businessUid'];
      const staffData = await staffService.fetchStaffData(staffUid, businessUid);
      commit('setStaffData', staffData);
    } catch (e) {
      console.error('error', e);
    }
  },
  async fetchPermissions({ rootGetters, commit, state }) {
    if (state.permissions.length) return;
    try {
      const staffUid = rootGetters['AuthStore/staffUid'];
      const permissions = await staffService.fetchPermissions(staffUid);
      commit('setPermissions', permissions);
    } catch (e) {
      console.error('error', e);
    }
  },
  async fetchStaffDataByUid({ rootGetters, commit }, staffUid) {
    try {
      const businessUid = rootGetters['AuthStore/businessUid'];
      const staffData = await staffService.fetchStaffData(staffUid, businessUid);
      commit('setStaffData', staffData);
      return staffData;
    } catch (e) {
      console.error('error', e);
      throw e;
    }
  },
  async fetchAllStaff({ commit }) {
    try {
      const staff = await staffService.fetchAllStaff();
      commit('setAllStaff', staff);
    } catch (e) {
      console.error('error', e);
    }
  },
  async toggleStaffCrmSettings({ rootGetters, commit }) {
    try {
      const staffUid = rootGetters['AuthStore/staffUid'];
      const staffSettings = await staffService.toggleStaffCrmSettings(staffUid);
      const staffCrmSettings = staffSettings.crm_settings;
      commit('setStaffData', { id: staffUid, new_crm_enabled: staffCrmSettings.new_crm_enabled, crm_settings_exists: staffCrmSettings.crm_settings_exists });
    } catch (e) {
      console.error('error', e);
    }
  },
  async setStaffTeamsView({ rootGetters, commit }, teamViewState) {
    try {
      const staffUid = rootGetters['AuthStore/staffUid'];
      commit('setStaffTeamsView', teamViewState);
      const data = teamViewState === 'all' ? '' : staffUid;
      outgoingPostMessageUtils.post('update-teams-view-state', data);
    } catch (e) {
      console.error('error', e);
    }
  },
};

const getters = {
  staffUid: (state, _, __, rootGetters) => rootGetters['AuthStore/staffUid'],
  staffData: (state, localGetters) => state.staffData[localGetters.staffUid] || {},
  staffDisplayName: (state, localGetters) => state.staffData[localGetters.staffUid].display_name,
  staffRole: (state, localGetters) => state.staffData[localGetters.staffUid].role,
  staffEmail: (state, localGetters) => state.staffData[localGetters.staffUid].email,
  staffTeamsViewEnabled: (state, localGetters) => state.staffData?.[localGetters.staffUid]?.teams_view_enabled ?? false,
  staffTeamsViewState: (state) => state.staffTeamsViewState,
  staffDataLoaded: (state) => state.staffDataLoaded,
  staffDataByUid: (state) => (uid) => state.staffData[uid],
  permissions: (state) => state.permissions,
  hasPermission: (state) => (permission) => state.permissions.includes(permission),
  allStaffData: (state) => state.staffData,
  allStaffLoaded: (state) => state.allStaffLoaded,
  isCollaborator: (state) => ['admin', 'manager', 'collaborator'].includes(state?.staffData[state.staffUid]?.role),
  staffList: (state) => state.staffList,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
