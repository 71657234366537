import httpService from '@/modules/common/services/httpService';

const baseRoute = '/v3/sales/reports/';
const widgetRoute = 'payments_widget';
const forcastRoute = 'forecast_payments';

const getWidgetData = async () => httpService.get(`${baseRoute}${widgetRoute}`)
  .then((response) => response.data);

const getForecastData = async () => httpService.get(`${baseRoute}${forcastRoute}`)
  .then((response) => response.data);

export default {
  getWidgetData,
  getForecastData,
};
