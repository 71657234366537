<template>
  <VcLayout class="forecast-graph">
    <VcLayout v-if="isLoading">
      <VcSkeleton type="paragraph" width="100%" height="30px"/>
    </VcLayout>

    <VcEmptyState v-else-if="!hasData"
                  :title="$t('widget.sales_widget.forecast_graph.empty')"
                  subtitle=""
                  size="md"
                  :image="require('@/assets/images/payments/product.svg')"
                  data-qa="ForecastGraphEmptyState"/>

    <VcLayout v-else align-center>
      Found forecast data
    </VcLayout>
  </VcLayout>
</template>

<script>

export default {
  name: 'ForecastGraph',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    forecastData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasData() {
      return Object.keys(this.forecastData ?? {}).length > 0;
    },
  },
};
</script>

<style scoped lang="scss">
.forecast-graph {
  min-height: 300px;

  .vc-empty-state {
    margin-top: 20px;
  }
}
</style>
