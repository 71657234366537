import httpService from '@/modules/common/services/httpService';
import gatewayService from '@/modules/common/services/gatewayService';
import importUtil from '@/modules/import/utils/importUtil';

const downloadTemplateService = httpService.create(
  window.pov_config.VUE_APP_API_URL_GATEWAY,
  false,
  {
    responseType: 'blob',
  },
);

const JOB_TYPES = {
  validate: 'validate',
  execute: 'execute',
};

const PROVIDER_TYPES = {
  excel: 'excel',
  import_job: 'import_job',
};

const basePath = '/business/import';
const uploadBasePath = '/v3/integrations/import_jobs';

const importService = {
  downloadTemplate(entity) {
    return downloadTemplateService.get(`${basePath}/template/download?entity=${entity}`)
      .then((response) => {
        importUtil.downloadFile(response.data, 'template.xlsx');
      })
      .catch((error) => {
        console.error('Error downloading the template file:', error);
        throw error;
      });
  },
  uploadFile(entity, fileData, fileType = PROVIDER_TYPES.excel) {
    const formData = new FormData();
    formData.append('entity_type', entity);
    formData.append('provider_type', fileType);
    formData.append('provider_data[file]', fileData);
    formData.append('job_type', JOB_TYPES.validate);

    return gatewayService.post(`${uploadBasePath}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => response.data);
  },
  importJobStatus(jobUid) {
    return gatewayService.get(`${uploadBasePath}/${jobUid}`).then((response) => response.data);
  },
  executeImportJob(entity, jobUid) {
    const formData = new FormData();
    formData.append('entity_type', entity);
    formData.append('provider_type', PROVIDER_TYPES.import_job);
    formData.append('provider_data[source_import_job_uid]', jobUid);
    formData.append('job_type', JOB_TYPES.execute);

    return gatewayService.post(`${uploadBasePath}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => response.data);
  },
};

export default importService;
