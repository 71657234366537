<template>
  <VcLayout class="payment-widget-list">
    <VcLayout v-if="isLoading">
      <VcSkeleton type="paragraph" width="100%" height="30px" data-qa="PaymentWidgetListSkeleton"/>
    </VcLayout>

    <VcLayout v-else-if="!hasData" column>
      <div class="payment-widget-list__empty-state-title">
        {{ $t('widget.sales_widget.payment_widget_list.ytd') }}
      </div>
      <VcEmptyState title=""
                    :subtitle="$t('widget.sales_widget.payment_widget_list.empty_state.title')"
                    size="sm"
                    :image="require('@/assets/images/payments/empty-payment-widget.svg')"
                    :button="$t('widget.sales_widget.payment_widget_list.empty_state.button')"
                    outlined-button
                    @click="onClickEmptyState"
                    class="my-auto flex-grow-0"
                    data-qa="PaymentWidgetListEmptyState">
      </VcEmptyState>
    </VcLayout>

    <VcLayout v-else column justify-space-between>
      <WidgetData :title="$t('widget.sales_widget.payment_widget_list.ytd')"
                  :value="ytdPayments"
                  :badge-value="shouldShowYtdBadge ? $percentageDisplay(ytdBadgeValue) : ''"
                  :badge-flavor="ytdBadgeFlavor"
                  :badge-tooltip="$t('widget.sales_widget.payment_widget_list.ytd_badge_tooltip')"
                  :empty-state="$t('widget.sales_widget.payment_widget_list.ytd_empty')"
                  @click="onClickYtd"
                  data-qa="PaymentWidget-Ytd"/>

      <WidgetData :title="$t('widget.sales_widget.payment_widget_list.pending_estimates')"
                  :value="pendingEstimatesCount"
                  @click="onClickPendingEstimates"
                  data-qa="PaymentWidget-PendingEstimates"/>

      <WidgetData :title="$t('widget.sales_widget.payment_widget_list.overdue_payments')"
                  :value="overduePaymentsTotal"
                  :empty-state="$t('widget.sales_widget.payment_widget_list.overdue_payments_empty')"
                  @click="onClickOverduePayments"
                  data-qa="PaymentWidget-OverduePayments">
        <template>
          <OverduePaymentsBreakdown :breakdown-data="overduePaymentsSummary"
                                    :currency="currency"
                                    @breakdown-click="onBreakdownClick"/>
        </template>
      </WidgetData>
    </VcLayout>
  </VcLayout>
</template>

<script>
import businessDateFormats from '@/modules/common/filters/BusinessDateFormats';
import WidgetData from './WidgetData.vue';
import OverduePaymentsBreakdown from './OverduePaymentsBreakdown.vue';

export default {
  name: 'PaymentWidgetList',
  components: {
    WidgetData,
    OverduePaymentsBreakdown,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    widgetData: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasData() {
      if (!this.currency) return false;
      return this.ytdPayments || this.pendingEstimatesCount || this.overduePaymentsTotal;
    },
    ytdPayments() {
      const value = this.widgetData?.total_payments?.data?.current_year;
      if (value && parseInt(value, 10) > 0) {
        return this.$priceDisplay(value, this.currency);
      }
      return '';
    },
    shouldShowYtdBadge() {
      const now = new Date();
      return now.getMonth() >= 3; // 01-04 or later
    },
    ytdBadgeValue() {
      const currentYearValue = this.widgetData?.total_payments?.data?.current_year ?? 0;
      const lastYearValue = this.widgetData?.total_payments?.data?.last_year ?? 0;
      return ((currentYearValue - lastYearValue) / lastYearValue) * 100;
    },
    ytdBadgeFlavor() {
      return this.ytdBadgeValue > 0 ? 'green' : 'red';
    },
    pendingEstimatesCount() {
      return this.widgetData?.pending_estimates?.data?.count;
    },
    overduePaymentsTotal() {
      const value = this.widgetData?.overdue_payments_summary?.data?.total_overdue;
      if (value && parseInt(value, 10) > 0) {
        return this.$priceDisplay(value, this.currency);
      }
      return '';
    },
    overduePaymentsSummary() {
      return this.widgetData?.overdue_payments_summary?.data;
    },
  },
  methods: {
    onClickEmptyState() {
      this.$emit('click-empty-state');
    },
    navigate(path, queryParams) {
      this.$emit('navigate', { path, queryParams });
    },
    onClickYtd() {
      const now = new Date();
      const startDate = new Date(now.getFullYear(), 0, 1);
      this.navigate('/app/payments/transactions', { start_date: businessDateFormats.dateFormatted(startDate) });
    },
    onClickPendingEstimates() {
      this.navigate('/app/payments/estimates', { selected_status: 'issued' });
    },
    onClickOverduePayments() {
      this.onBreakdownClick({ timeFrame: null });
    },
    onBreakdownClick({ timeFrame }) {
      const { startDate, endDate } = this.getDateRange(timeFrame);
      this.navigate('/app/payments/orders', {
        selected_status: 'past_due',
        due_date_start: startDate,
        due_date_end: endDate,
      });
    },
    getXDaysAgo(days) {
      const now = new Date();
      const today = new Date(now.setHours(0, 0, 0, 0));
      return new Date(today.setDate(today.getDate() - days));
    },
    getDateRange(timeFrame) {
      switch (timeFrame) {
        case 'last_week':
          return {
            startDate: businessDateFormats.dateFormatted(this.getXDaysAgo(7)),
            endDate: businessDateFormats.dateFormatted(new Date()),
          };
        case 'last_month':
          return {
            startDate: businessDateFormats.dateFormatted(this.getXDaysAgo(30)),
            endDate: businessDateFormats.dateFormatted(this.getXDaysAgo(8)),
          };
        case 'old':
          return {
            startDate: businessDateFormats.dateFormatted(this.getXDaysAgo(365)),
            endDate: businessDateFormats.dateFormatted(this.getXDaysAgo(31)),
          };
        default:
          return {};
      }
    },
  },
};
</script>

<style scoped lang="scss">
.payment-widget-list {
  min-height: 300px;

  &__empty-state-title {
    font-size: var(--font-size-small2);
    font-weight: var(--font-weight-large2);
    line-height: var(--size-value6);
  }
}
</style>
