import store from '@/store';
import communicationSdkService from '@/modules/common/services/communicationSdkService';
import handleNavigation from '@/utils/navigationUtils';

function getJWKSHandler(appCodeName) {
  return communicationSdkService.getJWKS(appCodeName);
}

function getUserHandler() {
  return {
    brandHost: window.location.origin,
    businessUid: store.getters['AuthStore/businessUid'],
    staffUid: store.getters['StaffStore/staffUid'],
    impersonate: store.getters.isImpersonate,
    language: store.getters['BusinessStore/locale'],
  };
}

/**
 * Open a modal, it could be an Angular/Frontage-vue modal or a POV modal
 * @param modalName - the name of the modal to open, as defined in the modal store
 * @param modalProps - the props to pass to the modal, either as Array or Object
 */
const openModal = ({ modalName, modalProps }) => {
  let internalName = modalName;
  let enhancedModalProps = modalProps;

  switch (modalName) {
    case 'AppMarket':
      internalName = 'AppMarketWizard';
      // App market first params are $scope and $state, so this is to prevent exceptions
      enhancedModalProps = [{}, {}];
      if (modalProps && Array.isArray(modalProps)) {
        enhancedModalProps = [...enhancedModalProps, ...modalProps];
      } else if (modalProps && modalProps.appCodeName) {
        enhancedModalProps = [...enhancedModalProps, modalProps.appCodeName];
      }
      break;
    default:
      break;
  }
  store.dispatch('ModalStore/openModal', {
    name: internalName,
    payload: enhancedModalProps,
  });
};

export function startCommunicationWithSdk(appDomain, iframeId, appId) {
  const channel = new MessageChannel();
  const frontageChannel = channel.port1;
  const externalAppChannel = channel.port2;

  function iframeMessageChannelCreator(event) {
    if (event.origin !== appDomain || event.data.widgetUid !== appId) {
      return;
    }
    if (event.data.type === 'ReadyWidgetEvent') {
      window.frames[iframeId].contentWindow.postMessage('InitWidgetEvent', appDomain, [externalAppChannel]);
      // Remove the event listener after the channel is created
      window.removeEventListener('message', iframeMessageChannelCreator);
    }
  }
  window.addEventListener('message', iframeMessageChannelCreator);

  return frontageChannel;
}

/*
* @param {string} path - The path to navigate to can be relative or absolute
* @param {object} queryParams - The query params to be added to the url
* @param {string} type - The type of navigation, can be relative or external
* @param {boolean} newTab - A flag to open the link in a new tab
* */
async function navigate({
  path, queryParams, type, newTab = false,
}) {
  // We not just pass the param to the util because this function can be used as
  // 3rd party apps, and it can cause security issue to pass it as is
  if (type === 'relative') {
    handleNavigation({ path, queryParams });
  } else if (type === 'external') {
    window.open(path, newTab ? '_blank' : '_self');
  }
}

const actions = {
  GetJWKSToken: getJWKSHandler,
  GetUser: getUserHandler,
  OpenModal: openModal,
  Navigate: navigate,
};

function communicationSdk(action, params) {
  if (actions[action]) {
    return actions[action](params);
  }
  console.error(`No handler was found for action "${action}"`);
  return {
    error: 'Invalid action',
  };
}

export default communicationSdk;
