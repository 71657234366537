<template>
  <div ref="component" class="scheduling-widget">
    <VcLayout column class="pa-5" fill-height>
      <div class="widget-title">{{ $t('widget.scheduling_widget.title') }}</div>
      <VcLayout class="sections-wrapper mt-4">
        <VcLayout column class="upcoming-bookings">
          <div class="section-title mb-3">{{ $t('widget.scheduling_widget.upcoming_bookings') }}</div>
          <div v-if="meetings.length" class="upcoming-booking-wrapper">
            <BookingItem
              v-for="(booking, index) in meetings.slice(0,visibleBookings)"
              :key="booking.uid"
              :date="formatWeekdayAndDay(booking.start_time_full)"
              :title="booking.client ? booking.client.full_name : $t('widget.scheduling_widget.event')"
              :subtitle="booking.title"
              :start-time="booking.start_time"
              :end-time="booking.end_time"
              :client="booking.client"
              :current-attendees="booking.current_attendees"
              :max-attendees="booking.max_attendees"
              :small="smallBookingItem"
              :class="{'mt-4':index > 0}"
              class="upcoming-booking-item"
              @click="onBookingClick(booking)"
            />
          </div>
          <VcLayout column fill-height>
            <div v-if="!meetings.length" class="empty-wrapper">
              <VcEmptyState
                :title="$t('widget.scheduling_widget.no_upcoming_bookings')"
                subtitle=""
                :button="$t('widget.scheduling_widget.schedule_appointment')"
                :image="require('@/assets/images/widgets/no_scheduling.svg')"
                outlined-button
                size="md"
                @click="onScheduleAppointmentClick"
              />
            </div>
            <SchedulingTotals
              v-if="!isFullSize"
              class="mt-4"
              :month-count="monthCount"
              :pending-approval-count="pendingApprovalCount"
              :show-month-count="showMonthCount"
              @onPendingApprovalClick="onPendingApprovalClick"
              @onMonthCountClick="onMonthCountClick"
            />
          </VcLayout>
        </VcLayout>
        <div v-if="visibleScheduleItems" class="upcoming-schedule">
          <div class="section-title mb-3">{{ $t('widget.scheduling_widget.upcoming_schedule') }}</div>
          <VcLayout wrap class="upcoming-schedule-items">
            <ScheduleItem
              v-for="day in days.slice(1, visibleScheduleItems)"
              :key="day.date"
              :date="formatWeekdayAndDay(day.date)"
              :meeting-num="day.meeting_num"
              :clients="day.clients"
              @click="onDayClick(day)"
              class="upcoming-schedule-item"
            />
            <!--    eslint-disable-next-line vuejs-accessibility/click-events-have-key-events       -->
            <div v-if="showFullScheduleLink" @click="onFullScheduleClick" class="full-schedule-link upcoming-schedule-item px-2">
              {{ $t('widget.scheduling_widget.view_full_schedule') }}
            </div>
          </VcLayout>
        </div>
        <SchedulingTotals
          v-if="isFullSize"
          :month-count="monthCount"
          :pending-approval-count="pendingApprovalCount"
          :show-month-count="showMonthCount"
          @onPendingApprovalClick="onPendingApprovalClick"
          @onMonthCountClick="onMonthCountClick"
        />
      </VcLayout>
    </VcLayout>
  </div>
</template>

<script>
import schedulingWidgetService
  from '@/modules/widgets/schedulingWidget/services/schedulingWidgetService';
import BookingItem from '@/modules/widgets/schedulingWidget/components/BookingItem.vue';
import ScheduleItem from '@/modules/widgets/schedulingWidget/components/ScheduleItem.vue';
import SchedulingTotals from '@/modules/widgets/schedulingWidget/components/SchedulingTotals.vue';
import businessDateFormats from '@/modules/common/filters/BusinessDateFormats';
import { mapActions, mapGetters } from 'vuex';

const X_SMALL = 505;
const SMALL = 598;
const MEDIUM = 738;
const LARGE = 859;
const X_LARGE = 1031;

export default {
  name: 'SchedulingWidget',
  components: { SchedulingTotals, BookingItem, ScheduleItem },
  data() {
    return {
      componentWidth: 0,
      isLoading: false,
      monthCount: 0,
      pendingApprovalCount: 0,
      days: [],
      meetings: [],
    };
  },
  mounted() {
    window.addEventListener('resize', this.updateComponentWidth);
  },
  async created() {
    this.isLoading = true;
    this.addI18nModule('widgets-pov');
    await this.init();
    this.isLoading = false;
    this.$emit('ready');
    await this.$nextTick();
    this.updateComponentWidth();
  },
  computed: {
    ...mapGetters('StaffStore', ['staffTeamsViewState']),
    isFullSize() {
      return this.componentWidth >= X_LARGE;
    },
    showMonthCount() {
      if (this.componentWidth >= X_LARGE) return true;
      if (!this.monthCount) return false;
      return !this.pendingApprovalCount;
    },
    visibleBookings() {
      if (this.componentWidth < X_SMALL) return 2;
      if (this.componentWidth < LARGE) return 1;
      if (this.componentWidth < X_LARGE) return 2;
      return 3;
    },
    visibleScheduleItems() {
      if (this.componentWidth < X_SMALL) return 0;
      if (this.componentWidth < SMALL) return 3;
      if (this.componentWidth < MEDIUM) return 5;
      return 6;
    },
    showFullScheduleLink() {
      return this.componentWidth >= MEDIUM;
    },
    smallBookingItem() {
      return this.componentWidth > X_SMALL && this.componentWidth < LARGE;
    },
  },
  methods: {
    ...mapActions('ModalStore', ['openModal']),
    updateComponentWidth() {
      if (this.$refs.component) {
        this.componentWidth = this.$refs.component.offsetWidth;
      }
    },
    formatWeekdayAndDay(date) {
      return businessDateFormats.formatWeekdayAndDay(date);
    },
    async init() {
      const calendarSummary = await schedulingWidgetService.getCalendarSummary(this.staffTeamsViewState);
      this.monthCount = calendarSummary.this_month_count;
      this.pendingApprovalCount = calendarSummary.pending_appproval_count;
      this.days = calendarSummary.days;
      this.meetings = calendarSummary.meetings;
    },
    handleCalendarNavigation(subjectType, params) {
      const calendarPath = '/app/scheduler';
      switch (subjectType) {
        case 'calendar':
          return this.onNavigate(calendarPath);
        case 'week':
          return this.onNavigate(calendarPath, { view: 'week' });
        case 'month':
          return this.onNavigate(calendarPath, { view: 'month' });
        case 'Appointment':
          return this.onNavigate(`/app/appointments/${params.uid}`);
        case 'EventInstance':
          return this.onNavigate(`/app/events/${params.uid}`);
        case 'day': {
          const date = businessDateFormats.dateFormatted(params.date);
          return this.onNavigate(calendarPath, { view: 'day', date });
        }
        case 'appointmentListPendingApproval':
          return this.onNavigate('/app/appointment-list', { filter: 'pending_business' });
        default:
          return this.onNavigate(calendarPath);
      }
    },
    onNavigate(path, queryParams) {
      this.$emit('sdkAction', 'Navigate', {
        type: 'relative',
        path,
        queryParams,
      });
    },
    onBookingClick(booking) {
      this.handleCalendarNavigation(booking.subject_type, { uid: booking.uid });
    },
    onScheduleAppointmentClick() {
      this.openModal({
        name: 'NewAppointment',
        functionName: 'open',
      });
    },
    onDayClick(day) {
      this.handleCalendarNavigation('day', { date: day.date });
    },
    onFullScheduleClick() {
      this.handleCalendarNavigation('week');
    },
    onMonthCountClick() {
      this.handleCalendarNavigation('month');
    },
    onPendingApprovalClick() {
      this.handleCalendarNavigation('appointmentListPendingApproval');
    },
  },
  watch: {
    staffTeamsViewState() {
      this.init();
    },
  },
};
</script>

<style scoped lang="scss">
$xSmall: 505px;
$small: 598px;
$medium: 738px;
$large: 859px;
$xLarge: 1031px;

.scheduling-widget {
  container-type: inline-size;
  line-height: normal;
  text-align: start;
  align-self: normal;
  height: 100%;
}
.widget-title {
  font-size: var(--font-size-medium2);
  font-weight: var(--font-weight-large2);
}
.section-title {
  font-size: var(--font-size-small2);
  font-weight: var(--font-weight-large2);
}
.sections-wrapper {
  gap: 32px;
  flex-wrap: wrap;

  @container (min-width: #{$xSmall}) {
    flex-wrap: nowrap;
  }
  @container (min-width: #{$xLarge}) {
    gap: 73px;
  }
}
.upcoming-bookings {
  width: 100%;

  @container (min-width: #{$small}) {
    max-width: 232px;
  }
  @container (min-width: #{$large}) {
    max-width: 327px;
  }
  ::v-deep {
    .VcEmptyState .VcEmptyStateImage {
      height: 72px;
    }
  }
}
.upcoming-schedule {
  max-width: 425px;
  @container (min-width: #{$xLarge}) {
    width: 100%;
  }
}
.booking-this-month,
.pending-approval{
  cursor: pointer;
}
.full-schedule-link {
  font-size: var(--font-size-x-small);
  font-weight: var(--font-weight-large);
  align-items: center;
  text-align: center;
  line-height: var(--size-value6);
  cursor: pointer;
}
.upcoming-schedule-item {
  height: 131px;
  width: 131px;
  max-width: 131px;
  align-content: center;
}
.upcoming-schedule-items {
  gap: var(--size-value4);
}
.empty-wrapper {
  height: 100%;
  align-content: center;
}
</style>
