<template>
  <VcLayout :data-qa="dataQa" fill-height align-start column>
    <div class="embedded_loader" v-if="isLoading">
      <VcSkeleton type="card" width="100%" height="100%"/>
      <div class="embedded_loader--on-top">
        <VcLoader v-if="isTimeoutSkeleton"/>
      </div>
    </div>
    <component :is="component"
               v-show="!isLoading"
               :appId="appId"
               :appName="appName"
               :appUrl="appUrl"
               :componentData="componentData"
               @track="trackEvent"
               @sdkAction="sdkAction"
               @ready="removeLoader"/>
  </VcLayout>
</template>

<script>
import CoreWidgetExample from '@/modules/widgets/coreWidgetExample/CoreWidgetExample.vue';
import ClientsWidget from '@/modules/widgets/clientsWidget/components/ClientsWidget.vue';
import EmbeddedAppDelegatorIframe from '@/modules/app/components/EmbeddedAppDelegatorIframe.vue';
import SchedulingWidget from '@/modules/widgets/schedulingWidget/components/SchedulingWidget.vue';
import communicationSdk from '@/modules/common/util/communicationSdk';
import SalesWidget from '@/modules/widgets/salesWidget/components/SalesWidget.vue';

export default {
  name: 'EmbeddedAppDelegator',
  components: {
    iFrame: EmbeddedAppDelegatorIframe,
    CoreWidgetExample,
    SchedulingWidget,
    ClientsWidget,
    SalesWidget,
  },
  props: {
    appId: {
      type: String,
      required: true,
    },
    appName: {
      type: String,
      required: true,
    },
    appUrl: {
      type: String,
    },
    component: {
      type: String,
      required: true,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    componentData: {
      type: Object,
    },
    dataQa: {
      type: String,
      default: 'EmbeddedAppDelegator',
    },
  },
  data() {
    return {
      isLoading: this.showLoader,
      isTimeoutSkeleton: false,
      timeToMimicLoading: 3000,
    };
  },
  created() {
    setTimeout(() => {
      if (this.isLoading) {
        this.isTimeoutSkeleton = true;
      }
    }, this.timeToMimicLoading);
  },
  methods: {
    removeLoader() {
      this.isLoading = false;
    },
    trackEvent(actionName, payload = {}) {
      this.$track('Widget Dashboard Action', {
        Name: this.appName,
        Type: this.component,
        Action: actionName,
        ...payload,
      });
    },
    async sdkAction(actionName, payload) {
      const enrichData = { ...payload, app_code_name: this.appName };
      if (actionName === 'SetState' && payload === 'ready') {
        this.removeLoader();
        return;
      }
      try {
        await communicationSdk(actionName, enrichData);
        this.trackEvent(actionName, enrichData);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.embedded_loader {
  width: 100%;
  height: 100%;
  position: relative;

  &--on-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
