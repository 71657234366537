<template>
  <VcLayout column class="overdue-breakdown-list">
    <div v-for="item in breakdownItems"
         :key="item.interval"
         class="overdue-breakdown text-truncate"
         @click="onBreakdownClick(item)"
         @keydown.enter="onBreakdownClick(item)" tabindex="0">
      <span class="badge me-2" :class="`color-${item.color}`">&nbsp;</span>

      <b>{{ item.interval }}</b>
      &nbsp;|&nbsp;
      <span>{{ item.count }}</span>
      &nbsp;|&nbsp;
      <span>{{ item.amount }}</span>
    </div>
  </VcLayout>
</template>

<script>
const OVERDUE_PAYMENTS_BREAKDOWN = [
  {
    color: 'yellow',
    timeFrame: 'last_week',
    intervalKey: 'widget.sales_widget.payment_widget_list.overdue_breakdown.last_week',
    countKey: 'last_week_count',
    amountKey: 'last_week',
  },
  {
    color: 'orange',
    timeFrame: 'last_month',
    intervalKey: 'widget.sales_widget.payment_widget_list.overdue_breakdown.last_month',
    countKey: 'last_month_count',
    amountKey: 'last_month',
  },
  {
    color: 'red',
    timeFrame: 'old',
    intervalKey: 'widget.sales_widget.payment_widget_list.overdue_breakdown.old',
    countKey: 'old_count',
    amountKey: 'old',
  },
];

export default {
  name: 'OverduePaymentsBreakdown',
  props: {
    breakdownData: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  computed: {
    breakdownItems() {
      return OVERDUE_PAYMENTS_BREAKDOWN
        .filter((item) => this.breakdownData[item.countKey])
        .map((item) => ({
          ...item,
          // eslint-disable-next-line localization/no-logic-in-translations
          interval: this.$t(item.intervalKey),
          count: this.$t('widget.sales_widget.payment_widget_list.overdue_breakdown.count', { count: this.breakdownData[item.countKey] }),
          amount: this.$priceDisplay(this.breakdownData[item.amountKey], this.currency),
        }));
    },
  },
  methods: {
    onBreakdownClick(item) {
      this.$emit('breakdown-click', { timeFrame: item.timeFrame });
    },
  },
};
</script>

<style scoped lang="scss">
.overdue-breakdown-list {
  gap: var(--size-value2);
}

.overdue-breakdown {
  cursor: pointer;

  &:hover {
    color: var(--link-color);
  }

  .badge {
    display: inline-block;
    width: var(--size-value2);
    line-height: var(--size-value2);
    border-radius: 50%;

    &.color-yellow {
      background-color: var(--yellow);
    }

    &.color-orange {
      background-color: var(--orange);
    }

    &.color-red {
      background-color: var(--red);
    }
  }
}
</style>
