<template>
  <VcLayout column class="sales-widget pa-6">
    <div class="sales-widget__title">{{ $t('widget.sales_widget.title') }}</div>
    <VcLayout justify-space-between class="mt-4">
      <VcLayout class="sales-widget__data">
        <PaymentWidgetList :isLoading="isWidgetLoading" :widgetData="widgetData" :currency="currency"
                           @navigate="navigate" @click-empty-state="goToPaymentWizard"/>
      </VcLayout>
      <VcLayout class="sales-widget__forecast">
        <ForecastGraph :isLoading="isForecastLoading" :forecastData="forecastData"/>
      </VcLayout>
    </VcLayout>
  </VcLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PaymentWidgetList from './PaymentWidgetList.vue';
import ForecastGraph from './ForecastGraph.vue';

export default {
  name: 'SalesWidget',
  components: {
    PaymentWidgetList,
    ForecastGraph,
  },
  data() {
    return {
      isWidgetLoading: false,
      isForecastLoading: false,
    };
  },
  computed: {
    ...mapGetters('PaymentsStore/SalesWidgetStore', ['widgetData', 'forecastData']),
    ...mapGetters('PaymentSettingsStore', ['currency']),
  },
  async created() {
    this.addI18nModule('widgets-pov');
    await this.init();
    this.$emit('ready');
  },
  methods: {
    ...mapActions('PaymentsStore/SalesWidgetStore', ['fetchWidgetData', 'fetchForecastData']),
    ...mapActions('PaymentSettingsStore', ['fetchSettings']),
    ...mapActions('ModalStore', ['openModal']),
    async init() {
      await Promise.all([
        this.fetchSettings(),
        this.widgetInit(),
        this.forecastInit(),
      ]);
    },
    async widgetInit() {
      this.isWidgetLoading = true;
      await this.fetchWidgetData();
      this.isWidgetLoading = false;
    },
    async forecastInit() {
      this.isForecastLoading = true;
      await this.fetchForecastData();
      this.isForecastLoading = false;
    },
    navigate({ path, queryParams }) {
      this.$emit('sdkAction', 'Navigate', {
        type: 'relative',
        path,
        queryParams,
      });
    },
    goToPaymentWizard() {
      this.$emit('sdkAction', 'OpenModal', {
        modalName: 'PaymentWizard',
        modalProps: [{ source: 'Sales Widget' }],
      });
    },
  },
};
</script>

<style scoped lang="scss">
$xSmall: 505px;
$small: 598px;
$medium: 738px;
$large: 859px;
$xLarge: 1031px;

.sales-widget {
  container-type: inline-size;
  color: var(--gray-darken-5);
  text-align: start;
  width: 100%;
  height: 100%;

  &__title {
    font-size: var(--font-size-medium2);
    font-weight: var(--font-weight-large2);
  }

  &__data {
    flex-grow: 1;

    @container (min-width: #{$small}) {
      flex-grow: 0;
    }
  }

  &__forecast {
    display: none;

    @container (min-width: #{$small}) {
      display: flex;
    }
  }
}
</style>
