import Vue from 'vue';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import mobileUtils from '@/utils/appUtils/mobileUtils';
import incomingPostMessageUtil from '@/utils/eventCommunicationUtils/incomingPostMessageUtil';
import { initNavigationUtil } from '@/utils/navigationUtils';
import App from './App.vue';
import ds from './plugins/designSystem';
import router from './router';
import store from './store';
import { i18n } from './plugins/i18n';
import AnalyticsPlugin from './plugins/analytics';
import '@/plugins/index';
import '@/../styles/variables.scss';

Vue.config.productionTip = false;
window.mobileUtils = mobileUtils;

new Vue({
  router,
  vuetify: ds,
  store,
  i18n,
  AnalyticsPlugin,
  render: (h) => h(App),
}).$mount('#app');

initNavigationUtil(router);
incomingPostMessageUtil.init();
outgoingPostMessageUtils.post('pov_ready'); // TODO: mobile-app.js.erb
