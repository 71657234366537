import salesWidgetService from '@/modules/widgets/salesWidget/services/salesWidgetService';

const state = {
  widgetData: {},
  forecastData: {},
};

const getters = {
  widgetData: (state) => state.widgetData,
  forecastData: (state) => state.forecastData,
};

const mutations = {
  setWidgetData(state, data) {
    state.widgetData = data;
  },
  setForecastData(state, data) {
    state.forecastData = data;
  },
};

const actions = {
  async fetchWidgetData({ commit }) {
    const response = await salesWidgetService.getWidgetData();
    if (response.success) {
      commit('setWidgetData', response.data);
    } else {
      commit('setWidgetData', {});
    }
  },
  async fetchForecastData({ commit }) {
    const response = await salesWidgetService.getForecastData();
    if (response.success) {
      commit('setForecastData', response.data);
    } else {
      commit('setForecastData', {});
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
